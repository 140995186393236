import cn from 'classnames';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { CaretDown, ArrowDownCircle, ArrowUpCircle } from 'src/common/icons';
import useOutsideClick from 'src/hooks/useOutsideClick';
import styles from './Dropdown.module.scss';

// TODO: Replace with real icons

const ArrowRightCircle = () => <i />;
const Check = () => <i />;
const Square = () => <i />;

export type SelectInputOption = {
  key: string | number;
  value: string | number | React.ReactNode;
  optionDisabled?: boolean;
  noBorderBottom?: boolean;
  icon?: React.ReactNode;
  datatest?:string
};

type DropdownProps = {
  title?: string;
  titleLink?: string;
  children?: React.ReactNode;
  className?: string;
  options?: Array<SelectInputOption>;
  variant?: 'default' | 'filter' | 'menu';
  selectedKeys?: any[];
  isMultiple?: boolean;
  isSortDropdown?: boolean;
  dropdownAnchor?: 'left' | 'right';
  sortDirection?: 'ASC' | 'DESC';
  dropdownDirection?: 'up' | 'down';
  icon?: React.ReactNode;
  disabled?: boolean;
  onClick?: (targetKey: any) => void;
};

export const Dropdown: React.FC<DropdownProps> = ({
  title,
  children,
  className,
  options,
  titleLink,
  variant = 'default',
  selectedKeys = [],
  isMultiple = false,
  isSortDropdown,
  dropdownAnchor = 'left',
  sortDirection = 'asc',
  disabled,
  dropdownDirection = 'down',
  icon = <CaretDown />,
  onClick = () => {},
  
  ...rest
}) => {
  const { useState, useCallback, useRef } = React;
  const [open, setOpen] = useState(false);
  const ref = useRef(null);
  const history = useHistory();
  const handleTitleClick = () => {
    if (!titleLink) return;

    history.push(titleLink);
  };

  React.useEffect(() => {
    const escapeListener = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        setOpen(false);
      }
    };
    document.addEventListener('keydown', escapeListener);

    return () => {
      document.removeEventListener('keydown', escapeListener);
    };
  }, []);

  const clickListener = useCallback(
    (e: React.MouseEvent) => {
      if (!isMultiple && ref.current && (ref.current! as any).contains(e.target)) {
        setOpen(false);
      }
    },
    [ref.current],
  );

  const handleClickOutside = () => {
    setOpen(false);
  };

  useOutsideClick(ref, handleClickOutside);

  return (
    <div
      className={cn([
        styles.Dropdown,
        styles[variant],
        { [styles.anchorRight]: dropdownAnchor === 'right' },
        className,
      ])}
      onClick={clickListener}
     
    >
      <button
        onClick={() => {
          setOpen((state) => !state);
        }}
        type="button"
        disabled={disabled}
        data-test="tools-dropdown"
      >
        {children}
        {icon}
      </button>

      {options && (
        <div
          ref={ref}
          role="menu"
          tabIndex={-1}
          className={cn({
            [styles.open]: open,
            [styles.directionUp]: dropdownDirection === 'up',
            [styles.withTitle]: title,
          })}
          data-test="options-dropdown"
          
        >
          {title && (
            <span className={cn(styles.title, titleLink ? styles.clickable : '')} onClick={handleTitleClick}>
              {title}
            </span>
          )}

          {options.map(({ key, value, optionDisabled, noBorderBottom, icon,datatest }) => (
            <span
              key={key}
              
              onClick={() => !optionDisabled && onClick(key)}
              role="menuitem"
              tabIndex={-1}
              className={cn([
                {
                  [styles.disabled]: optionDisabled,
                  [styles.selected]: selectedKeys?.includes(key),
                  [styles.noborder]: noBorderBottom,
                  [styles.isSort]: isSortDropdown,
                },
              ])}
              data-test={datatest || `dropdown-option-${key}`}           
            >
              {!isSortDropdown && selectedKeys?.includes(key) && (
                <span className={styles.check}>
                  <Check />
                </span>
              )}
              {!isSortDropdown && isMultiple && !selectedKeys?.includes(key) && (
                <span className={styles.square}>
                  <Square />
                </span>
              )}
              {isSortDropdown && selectedKeys?.includes(key) && (
                <>
                  {sortDirection === 'DESC' ? (
                    <>
                      <span className={styles.iconSelected}>
                        <ArrowDownCircle />
                      </span>
                      <span className={styles.icon}>
                        <ArrowUpCircle />
                      </span>
                    </>
                  ) : sortDirection === 'ASC' ? (
                    <>
                      <span className={styles.icon}>
                        <ArrowDownCircle />
                      </span>
                      <span className={styles.iconSelected}>
                        <ArrowUpCircle />
                      </span>
                    </>
                  ) : null}
                </>
              )}
              {icon && <span>{icon}</span>}
              {value}
            </span>
          ))}
        </div>
      )}
    </div>
  );
};

export default Dropdown;

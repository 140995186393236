import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useQueryClient } from 'react-query';
import * as Sentry from '@sentry/react';
import { useAssetMetadataMutation } from 'src/api/mutations/assetMutations';
import { useAssetSchemaQuery } from 'src/api/queries/schemesQueries';
import { queryNames } from 'src/api/utils/queryNames';
import { useAlerts } from 'src/common/AlertManager';
import { Pencil } from 'src/common/icons';
import { Asset } from 'src/types/TAssets';

import styles from '../Modal.module.scss';
import messages from '../messages';
import MetadataForm from 'src/components/MetadataForm';

import AssetMetadataSetup from './AssetMetadataSetup';
import { isEmpty, set } from 'lodash';
import useErrorMessages from 'src/hooks/useErrorsMessage';
import useRoleManager from 'src/hooks/useRoleManager';
import { Dropdown, Loader } from 'src/components';
import { ISubmitEvent } from '@rjsf/core';

type TAssetMetadata = {
  asset: Asset;
  schemas: any;
};

const renderMetadata = (isUser: any, schema: any, metadata: any, onEdit: any) => {
  return (
    <>
      <div className={styles.title}>
        {(schema as any)?.title ?? <FormattedMessage {...messages.customMetadata} />}
        {isUser ? (
          <></>
        ) : (
          <button className={styles.edit} onClick={onEdit}>
            <Pencil />
          </button>
        )}
      </div>
      {Object.entries(metadata).map(
        ([k, v]) =>
          k !== 'data_schema_id' && (
            <div className={styles.metaField} key={k}>
              <p>{k}</p>
              <div>{String(v)}</div>
            </div>
          ),
      )}
    </>
  );
};

type RenderEditMetadataProps = {
  schema?: any;
  metadata: any;
  onSubmit: ((e: ISubmitEvent<any>, nativeEvent: React.FormEvent<HTMLFormElement>) => any) | undefined;
  onCancel: () => void;
};

const RenderEditMetadata: React.FC<RenderEditMetadataProps> = ({ schema, metadata, onSubmit, onCancel }) => {
  return <MetadataForm schema={schema} metadata={metadata} onSubmit={onSubmit} onCancel={onCancel} />;
};

export const AssetMetadata: React.FC<TAssetMetadata> = ({ asset, schemas }) => {
  // const metadata = asset?.data ?? {}
  const data_schema_id = asset.data_schema_id ?? 0;

  const metadata =
    data_schema_id === 0
      ? Object.fromEntries(
        Object.entries(asset?.data ?? {})
          .filter(([k]) => k !== 'data_schema_id')
          .map(([k, v]) => [k, String(v)]),
      )
      : Object.fromEntries(Object.entries(asset?.data ?? {}).filter(([k]) => k !== 'data_schema_id'));

  const [metadataEditMode, setMetadataEditMode] = useState(false);
  const [schema, setSchema] = useState<any>();

  const [selectedSchemaId, setSelectedSchemaId] = useState(0);
  const [selectSchemaOptions, setSelectSchemaOptions] = useState<any>([]);
  const [schemaIdDataMapping, setSchemaIdDataMapping] = useState<any>({});
  const [schemaKeyValueMapping, setSchemaKeyValueMapping] = useState<any>({});

  const queryClient = useQueryClient();
  const { showSuccess, showAlert } = useAlerts();


  const { getErrorMessage } = useErrorMessages();
  const { isUser, isCreator } = useRoleManager();
  const metadataMutation = useAssetMetadataMutation(asset.id, {
    onError: (error, newData, context: any) => {
      setSelectedSchemaId(asset?.data_schema_id ? asset?.data_schema_id : 0);
      setSchema(schemaIdDataMapping?.[asset?.data_schema_id]?.schema);

      queryClient.setQueryData([queryNames.asset, asset.id], context.previousData);
      // TODO: Handle error, show feedback
      Sentry.captureMessage(
        `Failed metadata mutation for asset ${asset.id} with error: ${error.message}`,
        Sentry.Severity.Error,
      );

      showAlert(getErrorMessage('system.errorMessages.unknownError'));
    },
    onMutate: (updated_data) => {
      let data = { ...updated_data.data };

      setMetadataEditMode(false);
      const previousData = queryClient.getQueryData<Asset>([queryNames.asset, asset.id]);
      queryClient.setQueryData<Asset | undefined>([queryNames.asset, asset.id], (old) => {
        if (typeof old !== 'undefined') {
          old.data = data;
        }
        return old;
      });
      return { previousData };
    },
    onSuccess: (updated_data) => {
      showSuccess('Asset metadata updated sucessfully');
    }
  });

  // query the metadata
  const {
    data: schemaData,
    error: schemaError,
    isLoading: isSchemaLoading,
  } = useAssetSchemaQuery(
    data_schema_id,
    {
      staleTime: 1000 * 60 * 5,
      refetchOnMount: true,
    },
    { enabled: !!data_schema_id },
  );

  useEffect(() => {
    setSelectedSchemaId(asset?.data_schema_id);
    const keyValueMapping: any = {};
    const idSchemaMapping: any = {};

    if (!schemas) return;
    const options = schemas?.map((schema: any) => {
      keyValueMapping[schema.id.toString()] = schema.name.en;
      idSchemaMapping[schema.id] = schema;
      return { value: schema.name.en + ` (ID:${schema.id.toString()})`, key: schema.id.toString() };
    });

    setSchemaKeyValueMapping(keyValueMapping);
    setSchemaIdDataMapping(idSchemaMapping);
    setSelectSchemaOptions(options);
  }, [schemas, asset]);

  useEffect(() => {
    if (!schemaData) return;

    const { $schema, ...data } = schemaData?.schema ?? { schema: {} };

    setSchema(data);
  }, [schemaData]);

  const submitMetadata = async (schemaObject: any) => {
    await metadataMutation.mutateAsync({
      data: schemaObject?.formData,
      mode: 'REPLACE',
      data_schema_id: Number(selectedSchemaId),
    });
  };

  const submitSchema = async (schemaObject: any, schemaId: number) => {
    // console.log(schemaObject, schemaId);
    await metadataMutation.mutateAsync({
      data: schemaObject?.formData,
      mode: 'REPLACE',
      data_schema_id: schemaId,
    });
  };

  if (!schema && isEmpty(metadata)) {
    return (
      <div className={styles.metadataWrapper}>
        <AssetMetadataSetup onSubmitSchema={submitSchema} asset={asset} schemasData={schemas} />
      </div>
    );
  }
  const renderDropdownValue = (value: string) => {
    if (!value || value.length <= 28) {
      return value;
    }
    return `${value.slice(0, 25)}...`;
  };
  return (
    <>
      {selectSchemaOptions?.length > 0 && schemas?.length > 0 ? (
        <div className={styles.metadataWrapper}>
          <div className={styles.title}>Asset Data Schema</div>
          <Dropdown
            className={styles.dropD}
            isMultiple={false}
            options={selectSchemaOptions}
            onClick={(key) => {
              setSelectedSchemaId(key);
              setSchema(schemaIdDataMapping[Number(key)]?.schema);
              setMetadataEditMode(true);
            }}
          >
            {selectSchemaOptions?.length > 0 ? (
              `${renderDropdownValue(schemaKeyValueMapping[selectedSchemaId])} (ID: ${selectedSchemaId})`
            ) : (
              <br />
            )}
          </Dropdown>
          {!metadataEditMode &&
            renderMetadata(isUser, schema, metadata, () => {
              setMetadataEditMode(true && !isUser);
              // setSelectedSchemaId(asset.data_schema_id)
            })}
          {/* {metadataEditMode && renderEditMetadata(schema, metadata, submitMetadata, () => setMetadataEditMode(false))} */}
          {metadataEditMode && (
            <RenderEditMetadata
              schema={schema}
              metadata={metadata}
              onSubmit={submitMetadata}
              onCancel={() => {
                setMetadataEditMode(false);
                // setSelectedSchemaId(asset.data_schema_id)
                // setSchema(schemaIdDataMapping[asset?.data_schema_id]?.schema)
              }}
            />
          )}
        </div>
      ) : (
        <div style={{ textAlign: 'center', marginTop: '250px' }}>No MetaData Available!</div>
      )}
    </>
  );
};
